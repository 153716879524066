/**
 * since the attachments array follows a standard data structure,
 * this function should work for all contentTypes
 */
export function parseAttachments(attachments) {
  const imageAttachmentNames = [
    "HorizontalImage",
    "VerticalImage",
    "Logo",
    "Banner",
    "PillarImage",
    "BackgroundImage",
    "GenreImage",
    "SquareImage",
    "Profile_picture",
    "Character",
    "AppleTVImage",
  ];

  let mediaUrl;
  if (process.server) {
    mediaUrl = process.env.IMG_HOST;
  } else {
    mediaUrl = useAppConfig().mediaUrl;
  }

  const output = {};
  const parsedArray = attachments
    ?.filter((a) => imageAttachmentNames.includes(a.name))
    ?.map((attachment) => {
      // then parse the array into our object
      let url = `${mediaUrl}${attachment.value}`;
      return {
        name: attachment.name,
        value: encodeURI(url).replaceAll("(", "%28").replaceAll(")", "%29"),
      };
    });

  parsedArray?.forEach((attachment) => {
    output[attachment.name.toLowerCase()] = attachment.value;
  });

  return output;
}

/**
 * this function fetches the assets from either the aggregatedVideo or videoAsset
 */
function fetchMovieAssets(media) {
  const {
    aggregatedVideo,
    videoAsset,
    attachments: baseAttachments,
  } = media || {};
  const attachments =
    aggregatedVideo?.attachments || videoAsset?.attachments || baseAttachments;
  // if attachments arent found on these two arrays, throw an exception
  if (!attachments) {
    console.error(`Unable to find attachments array from movie`, media);
  }

  return parseAttachments(attachments);
}

/**
 * this function looks into the seasons array of the details
 * compare the route's externalContentId (ie season id)
 * against the externalId of a season iteration
 */
function fetchSeasonAssets(media) {
  const route = useRoute();
  const { externalContentId, externalSeasonId } = route.query;
  const seasons = media?.tvShowSeasons || media?.tvShowSeason;

  const matchingSeason = Array.isArray(seasons)
    ? seasons.find(
        (season) => season.externalId == externalContentId || externalSeasonId
      )
    : seasons;
  const attachments = matchingSeason?.attachments || media?.attachments;

  // if attachments aren't found on these two arrays, throw an exception
  if (!attachments) {
    console.error(`Unable to find attachments array from season`, media);
  }

  return parseAttachments(attachments);
}

function fetchSeriesAssets(media) {
  const attachments = media?.tvShowSeries?.attachments || media?.attachments;

  // if attachments aren't found on these two arrays, throw an exception
  if (!attachments) {
    console.error("Unable to find attachments array from series", media);
  }

  return parseAttachments(attachments);
}

function fetchExternalLinkAssets(media) {
  return parseAttachments(media.attachments);
}

/**
 * since there's a lot of sources where the images can be retrieved,
 * this function simplifies (not really) it by relying on the content type
 * and retrieving its attachments from the relevant object
 *
 * this should return an object
 * {
 *   attachmentName: "HorizontalImage" | "Logo" | "Banner",
 *   url: encodedUrl
 * }
 */
export default function (media) {
  if (!media) return;
  const type = media?.type;

  const safeContentType = type || "MOVIE";

  switch (safeContentType.toUpperCase()) {
    case "PODCAST":
    case "SERIES":
      return fetchSeriesAssets(media);
    case "SEASON":
      return fetchSeasonAssets(media);
    case "MOVIE":
    case "EPISODE":
      return fetchMovieAssets(media);
    case "BROWSER":
    case "THEME":
    case "PILLAR":
    case "LIVEPROGRAM":
    case "PLAYER":
    case "GENRE":
      return fetchExternalLinkAssets(media);
    default:
      try {
        return parseAttachments(media.attachments);
      } catch (err) {
        throw `Unable to determine the contentType, ${safeContentType}`;
      }
  }
}
